@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.login-form,
.register-form,
.test-selection,
.test-container,
.result-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
button {
  margin: 10px;
  padding: 5px;
}

.question {
  margin: 20px 0;
}

.timer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.error-message {
  color: red;
  font-weight: bold;
}

input {
  border: solid black 1px;
}

button{
  background-color: black;
  color: white;
}